import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Avatar, Divider, Icon, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import Experience, { ExperienceType } from "../Components/Experience";

// calculate days between today and date (in days) and choose the largest of 365 and that number
function max(date: Date, max: number) {
    const now = new Date()
    const diff = Math.ceil((now.getTime() - date.getTime() ) / (1000 * 3600 * 24))
    return Math.min(diff, max)
}

const Experiences = () => {
    const [experience, setExperience] = useState<ExperienceType[]>([
        {
            name: "Miles",
            // projectTitle: "Consultant",
            date: new Date(2024, 7, 1),
            // endDate: new Date(2023, 8, 1),
            position: "Consultant",
            description: <>
                Currently I am a IT consultant at Miles. My job is to assist customer in their projects and develop their business further.
                Between contracts I strive to improve myself, by going on conferences and completing certifications.
                <br />
                
                <Typography variant='h6'>Projects:</Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <Icon sx={{width: '150px'}}>
                                <img width={'130px'} src='https://www.noova.no/hubfs/Noova3.svg'/>

                            </Icon>
                        </ListItemIcon>
                        <ListItemText
                        primary="Noova energy systems is a cutting edge company providing electricity and improving energy efficiency. One of their primary selling point is their application Noova Energy, providing their team, as well as the customer key insight in the energy usage of customers. They also provide a platform called flex, a free seating platform, where customers may book desks and review ther usage of their office space."
                            />
                    </ListItem>
                </List>
                {/* Certifications attained or working on:
                <table align='center'style={{ marginTop: "10px", marginBottom: "10px"}}>
                    <tr>
                        <th style={{width: "50%"}}>Time</th>
                        <th style={{width: "50%"}}>Certification</th>
                    </tr>
                    <tr>
                        <td>August 2024 - on going</td>
                        <td>Google Cloud Cloud Developer (Professional)</td>
                    </tr>
                </table> */}
            </>,
            image: "miles_logo_red.png",
            html_url: "https://www.miles.no/",
            technologies: ["C#", "Azure"],
        },
        {
            name: "DIPS",
            projectTitle: "DIPS Wall",
            date: new Date(2023, 5, 1),
            endDate: new Date(2023, 7, 1),
            position: "Developer",
            description: <>During the summer of 2023, I worked at DIPS AS for a second time. This time, I contributed to the development of <a href='https://www.dips.com/dmag/full-oversikt-med-dips-wall'>DIPS WALL</a>, a product designed for displaying patient lists in hospitals. I enhanced the product's data presentation capabilities and played a key role in aligning it with our current architecture. This involved configuring the product to operate within our Kubernetes cluster and updating any outdated components.</>,
            image: "Dips_white-1.png",
            html_url: "https://www.dips.com/",
            technologies: ["C#", "Vue", "Kubernetes", "Docker", "Azure", "Git", "WPF"],
        },
        {
            name: "DIPS",
            projectTitle: "Synne",
            date: new Date(2022, 5, 1),
            endDate: new Date(2022, 7, 1),
            position: "Developer",
            description: <>During my time at DIPS, I developed a tool called Synne. Synne is a tool for generating synthetic patients in a DIPS enviroment. This tool is available at <a href='https://synne.dips.no/'>synne.dips.no</a> (I cannot guarantee that it is still supported). With this tool, we can create patients with specific conditions and other desired traits at will. Synne is used for testing and creating demo data for our customers.</>,
            image: "Dips_white-1.png",
            html_url: "https://www.dips.com/",
            technologies: ["C#", "React", "Docker", "Azure", "Git"],
        },
        {
            name: "University of Stavanger",
            projectTitle: "Student Assistant",
            date: new Date(2020, 5, 1),
            endDate: new Date(2022, 7, 1),
            description: <>
                As a student assistant, I have been involved in several subjects, including Basic Programming, Operating Systems and Systems Programming, Mathematical Methods 100, and Mathematical Methods 200. My responsibilities included assisting students with their assignments and supporting the professor with grading.
                <br />
                In addition, I developed an assignment on scheduling for Operating Systems and Systems Programming that was automatically graded in Go. This was done during the summer before the semester started.
                <br />
                Furthermore, I mentored new students for one semester in the fall of 2020.
                <br />
                Here is a summary of the subjects I have been involved in along with their corresponding semesters:
                <table align='center' style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <tr>
                        <th>Subject</th>
                        <th>Semester</th>
                    </tr>
                    <tr>
                        <td>Basic Programming</td>
                        <td>Spring 2021, Fall 2021</td>
                    </tr>
                    <tr>
                        <td>Operating Systems and Systems Programming</td>
                        <td>Fall 2021</td>
                    </tr>
                    <tr>
                        <td>Mathematical Methods 100</td>
                        <td>Fall 2020</td>
                    </tr>
                    <tr>
                        <td>Mathematical Methods 200</td>
                        <td>Spring 2021</td>
                    </tr>
                </table>
            </>,
            image: "uis.png",
            html_url: "https://www.uis.no/",
            technologies: ["Python", "Go", "Docker, Git"],
        },
        {
            name: "Geitvågen Drift AS",
            projectTitle: "Receptionist and other tasks",
            date: new Date(2016, 5, 1),
            endDate: new Date(2018, 7, 1),
            description: <>In 2016 I was mainly employed as a parking attendant, as I got older and more experience I received more responsibility. I worked there for two years, and during that time I also did other tasks such as cleaning, gardening and other tasks that needed to be done. In 2018 I was also employed as a full time receptionist. Note that this was a summer job, and I only worked there during the summer.</>,
            image: "geitvaagen.png",
            html_url: "https://geitvagen.com/",
        },
    ])

    useEffect(() => {
        // Remove future experiences
        const now = new Date()
        setExperience(experience.filter(e => e.date <= now))
    }, [experience])

    return <>
        <Typography variant="h3">Experience:</Typography>
        <Grid2 container>
            <Grid2 xs={12} md={11}>
                {experience.map((e, i) => {
                    return <>
                        <Experience key={i} project={e} />
                        {/* <Divider textAlign="right" sx={{
                            "&::before, &::after": {
                                borderColor: "secondary.light",
                            },
                        }} /> */}
                        {i !== experience.length - 1 ? <Divider sx={{ bgcolor: "secondary.light" }} light={false} /> : <></>}
                        {/* <Divider sx={{ bgcolor: "secondary.light" }} light={false}/> */}
                    </>
                })
                }
            </Grid2>
            <Grid2 md={1} sx={{ display: { xs: "none", md: "block" } }}>
                <Timeline sx={{ height: "105%" }}>
                    <TimelineItem sx={{ height: `${Math.floor(max(experience[0].endDate? experience[0].endDate: experience[0].date, 365)/365 * (80-30) + 30)}%`}}>
                        <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>{`Present`}</TimelineContent>
                    </TimelineItem>
                    {experience.map((e, i) => {

                        // Only add dot if previous year is different from current year

                        return <>
                            <TimelineItem sx={{ height: i === experience.length-1 ? "10%" : "100%"}}>
                                <TimelineSeparator>
                                    {
                                        (i === experience.length - 1 || (e.date.getFullYear() !== experience[i + 1].date.getFullYear())) ?
                                            <TimelineDot />
                                            :
                                            <TimelineDot sx={{ visibility: "hidden", height: 0, paddingY: 0, marginX: 0, marginY: -1 }} />
                                    }
                                    {/* <TimelineDot sx={{visibility: "hidden"}}/> */}
                                    <TimelineConnector />
                                </TimelineSeparator>
                                {(i === experience.length - 1 || (e.date.getFullYear() !== experience[i + 1].date.getFullYear())) ? <TimelineContent>{e.date.getFullYear()}</TimelineContent> : <TimelineContent sx={{ visibility: "hidden" }}>{e.date.getFullYear()}</TimelineContent>}
                            </TimelineItem>
                        </>

                    })
                    }


                    <TimelineItem sx={{height: "10%"}}>
                        <TimelineSeparator>
                            <TimelineDot />
                        </TimelineSeparator>
                        <TimelineContent noWrap overflow={'visible'}>2000👶</TimelineContent>
                    </TimelineItem>

                </Timeline>
            </Grid2>
        </Grid2>
    </>
}

export default Experiences