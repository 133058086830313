import { Card, Typography, Avatar } from "@mui/material";
import Grid2 from '@mui/material/Unstable_Grid2';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useEffect, useState } from "react";

const Bio = () => {
    const [age, setAge] = useState(-1)

    useEffect(() => {
        let age = -1
        const date = new Date()
        if (date.getMonth() === 11 && date.getDate() >= 11) {
            age = 0
        }

        age += date.getFullYear() - 2000
        setAge(age)

    }, [])

    return (
        <>
            <Grid2 style={{ marginTop: 50 }} container alignItems={"center"} marginBottom={2}>
                <Grid2 xs={12} md={8} justifyContent={"center"} alignItems={"center"} container>
                    <Grid2 xs={1} sx={{ display: { xs: "none", md: "block" } }}>
                        {/* FILLER GRID */}
                    </Grid2>

                    <Grid2 xs={12} md={10} marginBottom={2}>
                        <Card sx={{ height: "100%" }}>

                            <Typography variant="h4">A Little Bit About Myself</Typography>
                            <Typography variant="body1" paddingX={1}>
                            I am Stian Brekken Antonsen, a {age}-year-old developer studying a master's degree in computer technology from the University of Stavanger, Norway.
                            I have also completed a bachelor's in computer science at the University of Stavanger.
                            My study program specializes in secure and reliable systems, which have given me a deeper understanding of security and reliability principles, especially in distributed systems. 
                            I have also completed several smaller coding projects that demonstrate some of my skills and creativity. 
                            You can view them under recent projects below.
                            </Typography>
                            
                            <Typography variant="body1" paddingX={1} marginTop={1}>
                            In 2022, I published two papers: one was my bachelor's thesis on creating a statistics website for Covid-19 and analyzing how the search trends of the world changed during the pandemic; the other was a paper on the threat modeling tools Microsoft threat modeling tool and OWASP threat dragon.
                            I also worked as a developer at DIPS, a Norwegian company that provides software solutions for healthcare. I continued working with them the next summer, where I worked on a project called DIPS wall.
                            </Typography>
                            
                            <Typography variant="body1" paddingX={1} marginTop={1}>
                            I am proficient in various languages such as C#, TypeScript, Go, Python and more. I am always eager to learn new technologies and improve my coding abilities.
                            </Typography>
                        </Card>
                    </Grid2>

                    <Grid2 xs={1} sx={{ display: { xs: "none", md: "block" } }}>
                        {/* FILLER GRID */}
                    </Grid2>
                    <Grid2 xs={12} justifyContent={"center"} alignItems={"center"} container>
                        <Grid2 xs={4}>
                            {/* <TwitterIcon sx={{ cursor: "pointer", fontSize: 100 }} onClick={() => window.open("https://twitter.com/stibreant", '_blank')} htmlColor={"#1DA1F2"} fontSize={"large"} /> */}
                            {/* htmlColor={"#1DA1F2"} */}
                            <TwitterIcon sx={{ cursor: "pointer", fontSize: 50 }} onClick={() => window.open("https://twitter.com/stibreant", '_blank')} />
                        </Grid2>
                        <Grid2 xs={4}>
                            <GitHubIcon sx={{ cursor: "pointer", fontSize: 50 }} onClick={() => window.open("https://github.com/stibreant", '_blank')} />
                        </Grid2>
                        <Grid2 xs={4}>
                            {/* htmlColor={"#0a66c2"} */}
                            <LinkedInIcon sx={{ cursor: "pointer", fontSize: 50 }} onClick={() => window.open("https://www.linkedin.com/in/stian-antonsen-338a48207/", '_blank')}  />
                        </Grid2>

                    </Grid2>



                </Grid2>

                <Grid2 xs={3}
                    sx={{
                        display: { xs: "block", md: "none" }
                    }} />
                <Grid2 xs={6} md={4} sx={{marginTop: {xs: 5, md: 0}}}>
                    <Avatar
                        alt="Stian Brekken Antonsen"
                        src="111.jpg"
                        style={{ width: "100%", height: "100%", maxWidth: "800px"}}
                    />
                </Grid2>
            </Grid2>
        </>
    );
}
export default Bio;